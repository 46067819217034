import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=07f89a72&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=07f89a72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f89a72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VDivider,VImg})
